<template>
  <b-container class="sprint-back-content">
    <b-row style="min-height: 500px">
      <b-col cols="9" class="page-left">
        <slot> </slot>
      </b-col>
      <b-col cols="3" class="page-right">
        <div>
          <b-row align-h="end">
            <b-col cols="5" class="base" v-print="'#printer'">
              <span v-if="currentModuleId == 4" class="base-text print-statement">打印试题</span>
            </b-col>
            <b-col
              v-if="!showLog && !isRedo"
              class="base"
              @click="$router.go(-1)"
            >
              <span class="iconfont icon-fanhui1"></span>
              <span class="base-text">回训练基地</span>
            </b-col>
            <b-col
              v-if="isRedo && !showLog"
              class="base"
              @click="$router.go(-1)"
            >
              <span class="iconfont icon-fanhui1"></span>
              <span class="base-text">返回记录页</span>
            </b-col>
            <b-col v-if="showLog">
              <div
                class="collection-questions"
                :class="collectId ? 'collected' : ''"
              >
                <span class="iconfont icon-xingxing"></span>
                <span @click="collectHandleClick">{{
                  collectId ? "取消收藏" : "收藏试题"
                }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                thead-class="page-thead"
                tbody-class="page-tbody"
                outlined
                :fields="fields"
                :items="items"
              ></b-table>
            </b-col>
          </b-row>
          <b-row v-if="!showLog">
            <b-col class="text-left">答题进度</b-col>
          </b-row>
          <b-row v-if="!showLog">
            <b-col
              class="schedule-round"
              v-for="(item, index) in progresses"
              :key="index"
              cols="3"
            >
              <div :class="['round-progress', getProgressColorClass(item)]">
                {{ index + 1 }}
              </div>
            </b-col>
          </b-row>
          <!-- <el-popover
            target="class-video"
            triggers="focus"
            popper-class="class-video"
            placement="left"
            disabled
          >
            <slot name="video">
              <el-tabs v-drag class="goinvoice">
                <el-tab-pane label="微课视频">
                  <div class="fourprompt-text">
                    <div
                      v-for="(item, index) in questionInfos"
                      :key="index"
                      v-html="item.explanation !== null ? item.explanation : ''"
                    ></div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </slot> -->
          <div class="text-left prompt-item" >
            <b-button :disabled="mediaUrls && mediaUrls.length == 0" id="class-video" @click="viewed('video')">
              <span class="iconfont icon-shipin"></span>
              <span class="prompt-text">微课视频</span>
            </b-button>
          </div>
          <!-- </el-popover> -->
          <el-dialog
            title="微课视频"
            v-if="dialogVisible"
            :visible.sync="dialogVisible"
            custom-class="micro-lesson-video"
            width="50%"
            :modal="false"
            v-dialogDrag
            center
            :lock-scroll="false"
            :close-on-click-modal="false"
            @close="closeDialog"
          >
            <div class="cards-bottom">
              <div class="cards-left">
                <el-menu :default-openeds="['0']">
                  <el-submenu index="0">
                    <template slot="title">
                      <i class="iconfont icon-pinglun" aria-hidden="true"></i>
                      <span style="margin-left: 10px">微课视频</span>
                    </template>
                    <el-menu-item
                      v-for="(item2, index2) in mediaUrls"
                      :key="index2 + '-' + index2"
                      :index="index2 + '-' + index2"
                      @click="changeUrl(index2, index2)"
                    >
                      <span>微课视频{{ index2 + 1 }}</span>
                    </el-menu-item>
                  </el-submenu>
                </el-menu>
              </div>
              <div
                v-for="(item, index) in mediaUrls"
                v-show="index == lockIndex"
                :key="index"
                class="card-right"
              >
                <template v-for="(item2, index2) in mediaUrls">
                  <div
                    v-if="index == lockIndex && index2 == lockIndex2"
                    :key="index + '-' + index2"
                    class="play-frame"
                  >
                    <div style="height: 100%">
                      <iframe
                        :src="'/azmediaplayer.html?url=' + item2"
                        scrolling="no"
                        frameborder="no"
                        align="center"
                        class="video-iframe"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </el-dialog>
          <el-popover
            placement="left"
            width="800"
            trigger="click"
            class="prompt-popover"
            popper-class="popover-class"
          >
            <slot name="explanation">
              <el-tabs v-drag class="goinvoice">
                <el-tab-pane label="理解题意">
                  <div class="fourprompt-text">
                    <div
                      v-for="(item, index) in questionInfos"
                      :key="index"
                      v-html="item.explanation !== null ? item.explanation : ''"
                    ></div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </slot>
            <div
              slot="reference"
              class="text-left prompt-item"
              @click="viewed('explanation')"
            >
              <b-button>
                <span class="iconfont icon-dengpao-tianchong"></span>
                <span class="prompt-text">理解题意</span>
              </b-button>
            </div>
          </el-popover>
          <div v-click-outside="closeStandardAnswer">
            <el-popover
              placement="left"
              width="800"
              trigger="manual"
              class="parsing-popover"
              popper-class="parsing-class"
              v-model="answerShowDisabled"
            >
              <slot name="standardAnswerKey">
                <el-tabs v-drag class="goinvoice">
                  <el-tab-pane label="参考答案">
                    <div class="fourprompt-text">
                      <div
                        v-for="(item, index) in questionInfos"
                        :key="index"
                        v-html="item.standardAnswerKey"
                      ></div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </slot>
              <div
                slot="reference"
                class="text-left prompt-item"
                @click="viewed('standardAnswerKey')"
              >
                <b-button>
                  <span class="iconfont icon-cankaowenxian"></span>
                  <span class="prompt-text">参考答案</span>
                </b-button>
              </div>
            </el-popover>
          </div>
        </div>
      </b-col>
    </b-row>
    <GlobalPopup
      :popupsTitle="`点击标准答案后，本题目按照“答错”计算。提交答案后，查看标准答案不影响培优币积分！`"
      :popupsbuttomA="`取消`"
      :popupsbuttomB="`确定`"
      @OKbutton="okSelfScore"
      @NObutton="
        () => {
          modal1 = false;
        }
      "
      :isShow="modal1"
    ></GlobalPopup>
  </b-container>
</template>

<script>
import GlobalPopup from "@/components/global-popup/global-popup-modal";
import { mapState } from "vuex";
import {
  SavaData,
  CancelCollection,
  GetQuestionStateInfo,
} from "@/api/english-entry";

export default {
  data() {
    return {
      fields: [
        {
          key: "type",
          label: "题型",
        },
        {
          key: "diffecter",
          label: "难度",
        },
        {
          key: "score",
          label: "分值",
        },
      ],
      // showTip: false,
      targetName: "",
      // tipTitle: "",
      modal1: false,
      isAnswerShow: false,
      answerShowDisabled: false,
      collectId: null,
      explanationShow: false,
      answerCount: 0,
      // imgs: {
      //   1: require("@/assets/images/icon-zhengque@2x.png"),
      //   3: require("@/assets/images/icon-cuowu@2x.png"),
      //   0: require("@/assets/images/icon-kongbai@2x.png"),
      // },
      dialogVisible: false,
      lockIndex: 0,
      lockIndex2: 0,
    };
  },
  components: {
    GlobalPopup,
  },
  computed: {
    ...mapState(["trainingTest", "currentModuleId"]),
    showLog() {
      return this.$route.meta.showLog;
    },
  },
  props: {
    isRedo: {
      default: false,
      type: Boolean,
    },
    items: {
      default: function () {
        return [
          {
            type: "填空题",
            diffecter: "A",
            score: 10,
          },
        ];
      },
    },
    progresses: {
      default: function () {
        return [];
      },
    },
    questionInfos: {
      default: function () {
        return [];
      },
    },
    mediaUrls: {
      default: function () {
        return [];
      },
    },
    // groupAnswerStates: {
    //   default: function () {
    //     return [];
    //   },
    // },
  },
  mounted() {},
  created() {
    if (this.showLog) {
      this.getQuestionState();
    }
  },
  watch: {
    questionInfos: {
      
      handler(oldValue, newValue) {
        if (oldValue != newValue) {
          this.isAnswerShow = false;
          this.answerShowDisabled = false;
        }
      },
      immediate:true,
      deep:true,
      
    },
  },
  methods: {
    changeUrl(index, index2) {
      this.lockIndex = index;
      this.lockIndex2 = index2;
    },
    closeStandardAnswer() {
      this.answerShowDisabled = false;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    viewed(part) {
      this.answerCount = sessionStorage.getItem("answerCount");
      if (part == "explanation") {
        this.explanationShow = true;
      }
      if (part == "video") {
        this.dialogVisible = true;
      }
      if (part == "standardAnswerKey") {
        if (this.showLog) {
          // 重做
          this.answerShowDisabled = true;
        } else {
          if (this.isAnswerShow || this.answerCount == 1) {
            if (this.answerShowDisabled) {
              this.answerShowDisabled = false;
            } else {
              this.answerShowDisabled = true;
            }
          } else {
            this.modal1 = true;
          }
        }
      }
    },
    hasContent(part) {
      debugger;
      console.log("questionInfos", this.questionInfos);
      let num = 0;
      if (this.questionInfos.length > 0 && this.questionInfos[0][part]) {
        num++;
      }
      if (this.questionInfos.length > 0 && num > 0) return true;
      return false;
    },
    okSelfScore() {
      debugger;
      this.modal1 = false;
      this.answerShowDisabled = true;
      this.isAnswerShow = true;
      if (this.answerShowDisabled) {
        this.$emit("seeAnswer", this.isAnswerShow);
      }
    },
    getQuestionState() {
      let parmeter = {
        qId: this.redoQuestion.questionId,
        studentId: this.userInfo.studentId,
      };
      GetQuestionStateInfo(parmeter).then((res) => {
        if (
          res.data.result.collectionState !== 0 &&
          res.data.result.collectId
        ) {
          this.collectId = res.data.result.collectId;
        }
        // this.noteComment = res.result.noteComment;
        // this.noteId = res.result.noteId ? res.result.noteId : 0;
        // this.wordId = res.result.wordsId ? res.result.wordsId : 0;
        // this.changeScore = res.result.scoreId ? res.result.scoreId : 0;
        // console.log("获取答题状态", this.wordId, this.changeScore, res);
        // if (!this.noteComment) {
        //   this.isShow = true;
        // }
      });
    },
    collectHandleClick() {
      if (this.collectId === null) {
        //调用收藏
        SavaData({
          collectUserId: this.userInfo.studentId,
          relatedId: this.redoQuestion.questionId,
          product: "E",
        }).then((res) => {
          if (res.data.success) this.collectId = res.data.result;
        });
      } else {
        CancelCollection({ id: this.collectId }).then((res) => {
          if (res.data.success === true) this.collectId = null;
        });
        //取消收藏
      }
    },
    checkAnalysis() {
      for (let a of this.questionInfos) {
        if (a.answerAnalysis) {
          return false;
        }
      }
      return true;
    },
    getProgressColorClass(item) {
      let colorClass = "";
      switch (item.colorState) {
        case 1:
          colorClass = "progress-color-green";
          break;
        case 2:
          colorClass = "progress-color-yellow";
          break;
        case 3:
          colorClass = "progress-color-red";
          break;
        default:
          colorClass = "";
          break;
      }
      return colorClass;
    },
    backTraining() {
      this.$emit("backTraining");
    },
  },
};
</script>

<style scoped lang="scss">
.collection-questions {
  border: 1px solid #333;
  border-radius: 30px;
  color: #333;
  padding: 0.2rem;
  margin-top: 0.5rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  &.collected {
    border: 1px solid $bg-yellow;
    background-color: $bg-yellow;
    color: white;
    .icon-xingxing {
      color: white;
    }
  }
  .icon-xingxing {
    color: #333;
  }
}
.page-left {
  display: flex;
  flex-direction: column;
}
.sprint-back-content {
  background: white;
}
.page-right {
  background: #f9f9f9;
  padding: 20px;
}
// .white-color {
//   width: 25px;
//   height: 25px;
// }
.round-progress {
  width: 25px;
  height: 25px;
  line-height: 25px;
  background: #e5e5e5;
  border-radius: 50%;
  color: #fff;
  // margin-top: 2.5px;
}
// .default-round {
//   // width: 25px;
//   // height: 25px;
//   // // background: #e5e5e5;
//   // // border-radius: 50%;
//   // margin-top: 10px;
//   // background-image: url("../../assets/images/icon-kongbai@2x.png");
// }
.text-left {
  text-align: left;
  padding: 0.5rem 1rem;
}
.prompt-item {
  margin: 10px 0;
  color: #2cb774;
  padding: 0;
  .disabled {
    color: #dddddd !important;
    text-decoration: line-through !important;
    .prompt-text {
      text-decoration: none !important;
    }
    .icon-shipin,
    .icon-cihuiguanli_icon {
      color: #dddddd !important;
    }
  }
  .iconfont {
    padding-right: 0.5rem;
  }
  .icon-cankaowenxian {
    padding-left: 0.2rem;
  }
  .icon-shipin {
    font-size: 1.5rem;
    padding-right: 0.6rem;
    // color: #ffc13b;
  }
  .icon-cihuiguanli_icon {
    font-size: 1.5rem;
    padding-right: 0.4rem;
    color: #ffc13b;
  }
  .icon-dengpao-tianchong {
    font-size: 1.6rem;
  }
  .icon-cankaowenxian {
    font-size: 1.34rem;
  }
  .prompt-text {
    text-decoration: underline;
  }
  .btn-secondary {
    color: #2cb774;
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    display: flex;
    align-items: center;
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #2cb774;
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    display: flex;
    align-items: center;
    box-shadow: none;
  }
  .btn-secondary:focus,
  .btn-secondary.focus {
    box-shadow: none;
  }
}
.icon-duihao {
  color: #2cb774;
}
// .type-color-red {
//   background: #fa141a;
// }
.type-color-green {
  background: #2cb774;
}
.progress-color-yellow {
  background: #fbc94f;
}
.progress-color-red {
  background: #fa141a;
}
.progress-color-green {
  background: #2cb774;
}
.page-thead,
.page-tbody {
  th,
  td {
    border-right: 1px solid #e5e5e5;
  }
}
.schedule-round {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
}
.base {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  color: #333;
  cursor: pointer;
}
.icon-fanhui1 {
  // font-weight: bold;
  padding-right: 5px;
  font-size: 20px;
}
.base-text {
  text-decoration: underline;
}
.print-statement {
  cursor: pointer;
}
.class-video,
.understand-title,
.answer-parsing {
  max-width: 50rem;
  // border-color: #f0f0f0;
}
// .popover-content {
//   display: flex;
//   // & > span:nth-child(1) {
//   //   width: 2rem;
//   //   padding-right: 5px;
//   // }
// }
</style>
<style lang="scss">
.micro-lesson-video {
  overflow: hidden !important;
  height: 38rem;
  .el-dialog__header {
    background-color: #2cb774;
    display: flex;
    align-items: center;
    padding: 10px 20px;
  }
  .el-dialog__title {
    color: #fff !important;
  }
  .el-dialog__headerbtn {
    top: 10px;
  }
  .el-dialog__body {
    height: 28rem;
    padding: 0;
  }
  // .play-frame {
  //   margin: 0 auto;
  //   height: 100%;
  // }
}
.cards-bottom {
  display: flex;
  border-radius: 10px;
  margin: 10px;
}
.cards-left {
  width: 250px;
  margin-right: 10px;
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  box-sizing: border-box;
}
.card-right {
  width: 100%;
  height: 550px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.play-frame {
  margin: 0 auto;
  height: 100%;
}
.el-icon-close:before {
  color: #e5e5e5;
}
.video-iframe {
  height: 100%;
  width: 100% !important;
}
.page-thead {
  tr {
    & > th:nth-child(1) {
      width: 40%;
    }
    & > th:nth-child(2) {
      width: 30%;
      border-right: 1px solid #dee2e6;
      border-left: 1px solid #dee2e6;
    }
    & > th:nth-child(3) {
      width: 25%;
    }
  }
  th {
    padding: 0.7rem 0.75rem;
    text-align: center;
    border-bottom: none !important;
  }
}
.page-tbody {
  tr {
    td {
      vertical-align: middle;
    }
    & > td:nth-child(2) {
      border-right: 1px solid #dee2e6;
      border-left: 1px solid #dee2e6;
    }
  }
  td {
    padding: 0.75rem 0.5rem;
    text-align: center;
  }
}
.class-video,
.understand-title,
.answer-parsing {
  .popover-header {
    background-color: #2cb774;
    color: #fff;
  }
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #2cb774;
  background-color: #2cb774;
}
.popover-class,
.parsing-class {
  position: fixed;
  width: 30% !important;
  height: 0;
  border: none;
  background: none;
  box-shadow: none;
  top: 31rem;
  right: 47rem;
  padding: 0;
  .goinvoice {
    background-color: #fff;
    box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    & > div:nth-child(1) {
      background-color: #2cb774;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding-left: 1rem;
      & > span:nth-child(2) {
        cursor: pointer;
        width: 4%;
        font-size: 24px;
        display: flex;
        justify-content: flex-end;
      }
    }
    .farm-html {
      padding: 16px;
      max-height: 44rem;
      overflow: scroll;
    }
  }
  .el-tabs__item.is-active {
    color: #fff;
  }
  .fourprompt-text {
    padding: 1rem 1rem 0 1rem;
  }
  .el-tabs__item {
    height: 45px;
    line-height: 45px;
    font-size: 16px;
  }
}
</style>
